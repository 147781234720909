import React from "react";
import ArrowRight from "@/images/icon-arrow-right.inline.svg";

export default ({ children, foreground, background, className, ...props }) => {
    let classes = ["block relative transform"];

    if (className) classes.push(className);

    return (
        <figure className={classes.join(" ")} {...props}>
            {children}
            <div className="absolute inset-0 flex items-center justify-center text-white transition-all opacity-0 backdrop-blur group-hover:opacity-100">
                <div
                    className="absolute inset-0 bg-dark opacity-80"
                    style={background ? { backgroundColor: background } : {}}
                />
                <ArrowRight
                    className="z-10 w-1/5 transition-all translate-y-2 h-1/5 transform-gpu group-hover:translate-y-0"
                    style={foreground ? { color: foreground } : {}}
                />
            </div>
        </figure>
    );
};
