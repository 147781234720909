import React from "react";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import AccessoryImage from "~/accessory-image";

export default ({ to, title, image, ...props }) => {
    const meta = "metadata" in image.asset ? image.asset.metadata : null;

    return (
        <div className="flex items-center justify-center my-2xl">
            <Link
                to={to}
                className="relative flex items-center max-w-sm mx-auto overflow-hidden text-sm no-underline transition-shadow bg-white rounded-md shadow-xl group dark:bg-white dark:bg-opacity-5 hover:no-underline hover:shadow-2xl"
                {...props}
            >
                <AccessoryImage
                    className="w-16 overflow-hidden rounded-l"
                    background={meta ? meta.palette.color.background : null}
                    foreground={meta ? meta.palette.color.foreground : null}
                >
                    <GatsbyImage image={image.asset.gatsbyImageData} alt={image.alt || title} />
                </AccessoryImage>
                <div className="min-w-xxs px-md">
                    <div className="text-xs text-opacity-50 text-dark dark:text-white dark:text-opacity-50">
                        Als nächstes
                    </div>
                    <h3 className="m-0 font-bold text-inherit">{title}</h3>
                </div>
            </Link>
        </div>
    );
};
