import React from "react";
import Color from "color";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import BlockContent from "~/block-content";
import Column from "~/column";
import Container from "~/container";
import Layout from "~/layout";
import NextLink from "~/next-link";
import SEO from "~/seo";

const ProjectTemplate = ({ data, pageContext }) => {
    const project = data.project;
    const next = data.next;
    const mainImage = project.mainImage ? project.mainImage.asset : null;

    const colors = mainImage
        ? {
              backgroundColor: Color(mainImage.metadata.palette.background.background)
                  .mix(Color("white"), 0.85)
                  .hex(),
              backgroundColorDark: Color(mainImage.metadata.palette.backgroundDark.background)
                  .mix(Color("black"), 0.8)
                  .hex(),
          }
        : {};

    return (
        <Layout color={colors}>
            <div>
                <Container className="my-section">
                    <Column>
                        <header>
                            <h1
                                className="mb-0 text-hero"
                                style={
                                    mainImage
                                        ? { color: mainImage.metadata.palette.color.background }
                                        : {}
                                }
                            >
                                {project.title}
                            </h1>
                            <div className="font-bold text-variable">{project.location}</div>
                        </header>

                        {project._rawBody && (
                            <div className="max-w-prose mt-2xl text-variable">
                                <BlockContent blocks={project._rawBody || []} />
                            </div>
                        )}
                    </Column>
                </Container>
            </div>

            <div className="space-y-px sm:space-y-sm">
                {project.images.map((image, index) => (
                    <Container className="relative z-10 px-0" key={index}>
                        <figure>
                            <GatsbyImage
                                image={image.asset.gatsbyImageData}
                                alt={image.alt || ""}
                            />
                            {image.caption && (
                                <figcaption className="mt-3 text-sm font-bold leading-snug">
                                    <span className="block w-2 mb-1 bg-current h-2px" />
                                    {image.caption}
                                </figcaption>
                            )}
                        </figure>
                    </Container>
                ))}
            </div>

            {next && (
                <Container className="my-section">
                    <Column>
                        <NextLink
                            to={`/referenzen/${next.slug.current}`}
                            title={next.title}
                            image={next.mainImage}
                        />
                    </Column>
                </Container>
            )}
        </Layout>
    );
};

export const Head = ({ data, pageContext }) => (
    <SEO
        title={`${data.project.title} in ${data.project.location}`}
        description={`Sehen Sie sich das Projekt ${data.project.title} in ${data.project.location} und weitere Referenzen von Büro Schriever an.`}
        image={data.project.cover?.asset?.gatsbyImageData?.images?.fallback?.src}
    />
);

export default ProjectTemplate;

export const query = graphql`
    query ProjectTemplateQuery($id: String!, $next: String) {
        project: sanityProject(id: { eq: $id }, visible: { eq: true }) {
            id
            location
            title
            _rawBody
            cover: mainImage {
                asset {
                    gatsbyImageData(width: 1200, height: 800)
                }
            }
            mainImage {
                alt
                asset {
                    gatsbyImageData(width: 1440, height: 810)
                    metadata {
                        palette {
                            background: lightVibrant {
                                background
                            }
                            backgroundDark: darkVibrant {
                                background
                            }
                            color: vibrant {
                                background
                            }
                        }
                    }
                }
            }
            images {
                alt
                caption
                asset {
                    gatsbyImageData(width: 1440, height: 810)
                }
            }
        }
        next: sanityProject(id: { eq: $next }, visible: { eq: true }) {
            id
            title
            slug {
                current
            }
            mainImage {
                alt
                asset {
                    gatsbyImageData(width: 120, height: 120)
                    metadata {
                        palette {
                            color: vibrant {
                                background
                                foreground
                            }
                        }
                    }
                }
            }
        }
    }
`;
